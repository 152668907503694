import { Button, Grid, Box, Stack, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useMemo, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import InputForm from "../../components/molecules/forms/inputForm";
import type { RootState } from "../../store";

import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  submitLogin,
  removeRejectMassage,
  getAppVersion,
} from "../../store/feature/user";
import { yupResolver } from "@hookform/resolvers/yup";
import { ILogin } from "../../store/feature/user";
import Toast from "../../components/atoms/toast";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import LoginSchema from "../../utils/validation/login";
import { useNavigate } from "react-router-dom";
import { setToken } from "../../utils/storage";
import {
  MailOutline as MailOutlineIcon,
  Lock as LockIcon,
} from "@mui/icons-material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import indonesiaImg from "../../assets/logo/Indonesia.png";
import autentikaImg from "../../assets/logo/medium.png";
import { useTheme } from "@mui/material/styles";

const Login = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { rejectMessage, isLoading, accesToken, appVersion } = useAppSelector(
    (state: RootState) => state.user
  );
  const [isShowPass, setIsShowPass] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ILogin>({
    reValidateMode: "onChange",
    resolver: yupResolver(LoginSchema),
    defaultValues: {
      email: "",
      password: "",
      showPass: false,
    },
  });

  const onSubmit = async (data: ILogin) => {
    dispatch(submitLogin(data));
  };
  const endAdornment = useMemo(() => {
    if (isShowPass) {
      return <VisibilityIcon />;
    }
    return <VisibilityOffIcon />;
  }, [isShowPass]);

  const toggleShowPassword = () => {
    setIsShowPass((prev) => !prev);
  };
  console.log(errors, "error here");

  const handleCloseToast = () => {
    dispatch(removeRejectMassage());
  };

  console.log("accesstoken there ===> ", accesToken);
  useEffect(() => {
    if (accesToken) {
      setToken(accesToken);
      navigate("/", { replace: true });
      console.log("wah masuk k dashboard gan ==> ");
    }
  }, [accesToken]);

  useEffect(() => {
    dispatch(getAppVersion({}));
  });

  return (
    <div
      style={{
        backgroundImage: `url(${indonesiaImg})`,
        backgroundSize: "cover",
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <Container maxWidth="sm">
        <Grid
          container
          spacing={2}
          direction="column"
          justifyContent="center"
          sx={{ minHeight: "105vh" }}
        >
          <Stack
            style={{
              display: "grid",
              placeItems: "center", // Centers both horizontally and vertically
            }}
          >
            <Box
              bgcolor="#FFFFFF"
              borderRadius={"50%"}
              boxShadow={3}
              width={"100px"}
              height={"100px"}
              textAlign="center"
              marginBottom={"-50px"}
              zIndex={1000}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <img src={autentikaImg} height={"65px"} width={"65px"} />
            </Box>
            <Box
              pt={9}
              pb={5}
              pl={5}
              pr={5}
              bgcolor="#FFFFFF"
              borderRadius={4}
              boxShadow={3}
              maxWidth="500px"
              width="100%"
              textAlign="center"
            >
              <Grid
                container
                direction="column"
                component="form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Grid item>
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: "bold", marginBottom: "0.5rem" }}
                  >
                    Welcome to CMS Merah Putih
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bold",
                      marginBottom: "0.5rem",
                      color: "#83a4c9",
                    }}
                  >
                    Validation Authority
                  </Typography>
                  <Typography variant="body1" sx={{ marginBottom: "0.5rem" }}>
                    Version {appVersion}
                  </Typography>
                </Grid>
                <Grid item>
                  <InputForm
                    name="Email"
                    errors={errors}
                    control={control}
                    required
                    placeholder="Email"
                    maxLength={100}
                    isLoading={false}
                    sxEndAdornment={{
                      backgroundColor: "inherit",
                      border: "none",
                      cursor: "pointer",
                      width: "4rem",
                    }}
                    showStartAdornment
                    startAdornment={<MailOutlineIcon />}
                    sxFC={{ marginBottom: "1rem" }}
                  />
                </Grid>
                <Grid item>
                  <InputForm
                    name="Password"
                    errors={errors}
                    control={control}
                    type={isShowPass ? "text" : "password"}
                    placeholder="Password"
                    maxLength={127}
                    showEndAdornment
                    sxEndAdornment={{
                      backgroundColor: "inherit",
                      border: "none",
                      cursor: "pointer",
                      width: "4rem",
                    }}
                    endAdornment={endAdornment}
                    onClickEndAdornment={toggleShowPassword}
                    required
                    showStartAdornment
                    startAdornment={<LockIcon />}
                    sxFC={{ marginBottom: "1.5rem" }}
                  />
                </Grid>
                <Grid item>
                  {isLoading ? (
                    <ThemeProvider theme={theme}>
                      <LoadingButton
                        loading
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="outlined"
                        fullWidth
                        color="secondary"
                        sx={{ borderRadius: "12px", textTransform: "none" }}
                      >
                        Login
                      </LoadingButton>
                    </ThemeProvider>
                  ) : (
                    <ThemeProvider theme={theme}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ borderRadius: "12px", textTransform: "none" }}
                      >
                        Login
                      </Button>
                    </ThemeProvider>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Grid>
      </Container>
    </div>
  );
};

export default Login;
